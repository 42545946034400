import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Order from '@/components/Order/Order.vue';

export default _defineComponent({
  expose: [],
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Order, { isNew: true }))
}
}

})